import { IResponse } from '../../../models/Common';
import { Product, Warranty } from '../../../models/Product';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../../core/services/base/data.service';

@Injectable({
  providedIn: 'root'
})
export class MyharviaProductsService extends DataService<Product> {
  private _products: Product[] = [];
  public get products(): Product[] {
    return this._products;
  }
  public set products(value: Product[]) {
    this._products = value;
  }
  private _product?: Product | undefined;
  public get product(): Product | undefined {
    return this._product;
  }
  public set product(value: Product | undefined) {
    this._product = value;
  }
  private _warrantyDate?: Warranty | undefined;
  public get warrantyDate(): Warranty | undefined {
    return this._warrantyDate;
  }
  public set warrantyDate(value: Warranty | undefined) {
    this._warrantyDate = value;
  }

  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/myharvia/products');
  }

  getProducts = (page: number = 1, size: number = 4): Observable<IResponse<Product[]>> => {
    const uri = new URL('/api/myharvia/products', window.location.origin);
    uri.searchParams.set('page', `${page}`);
    uri.searchParams.set('size', `${size}`);
    console.log(
      'active:',
      this.activeFilter,
      this.activeFilter.map((f) => f.value),
    );
    if (this.activeFilter?.length) {
      const filter = this.activeFilter.map((f) => f.value);
      console.log('filter', filter);
      uri.searchParams.set('filter', JSON.stringify({ $and: filter instanceof Array ? filter : filter }));
    }
    return this.http.get<IResponse<Product[]>>(uri.toString());
  };

  getProductById = (id: string): Observable<Product> => this.http.get<Product>(`/api/myharvia/products/${id}`);

  changeProductState = (id: string, state: number): Observable<Product> =>
    this.http.patch<Product>(`/api/myharvia/products/${id}`, { state });

  getProductWarranty = (id: string): Observable<Warranty> => this.http.get<Warranty>(`/api/myharvia/products/warranty/${id}`);

  approveProduct = (_id: string, date: number, months: number): Observable<any> =>
    this.http.put<any>(`/api/myharvia/products/${_id}`, { date, months });
}
